import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../../components/Layout';
import useWindowSize from '../../../helpers/useWindowSize';

const JordenBrenner = ({ data }) => {
  const [width, height] = useWindowSize();

  return (
    <Layout>
      <section className='jordenbrenner'>
        <h1>jorden brenner og ingen bryr seg</h1>
        <h2>
          <Link to='/work'>2020/fanzine</Link>
        </h2>

        <Img
          fluid={data.image1.childImageSharp.fluid}
          alt='card form above'
          className='topimage'
        />

        <div className='mid-section'>
          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt='panthlet open'
            className='leftimage'
          />
          <p className='maintext'>
            «Jorden brenner og ingen bryr seg» is a fanzine that explores the
            emotional side of climate change among youth. It is based on
            interviews with five young adults, located in Oslo, Norway.
            <br />
            <br />
            The fanzine does not explore different political views, but focuses
            on how climate change is affecting the daily lives of youth and how
            it makes them feel.
            <br />
            <br />
            The fanzine includes text, illustration and commentary based on
            these interviews.
          </p>
          <img
            src={require('../../../images/jorden/3.jpg')}
            alt='bok'
            className='rightimage'
          />

          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt='panthlet open closeup'
            className='rightimage'
            style={{
              position: width > 1100 ? 'absolute' : 'relative',
            }}
          />

          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt='panthlet open closeup 2'
            className='image4'
            style={{
              position: width > 1100 ? 'absolute' : 'relative',
            }}
          />

          <Img
            fluid={data.image5.childImageSharp.fluid}
            alt='panthlet open closeup 3'
            className='image5'
            style={{
              position: width > 1100 ? 'absolute' : 'relative',
            }}
          />

          <Img
            fluid={data.image6.childImageSharp.fluid}
            alt='panthlet open closeup 4'
            className='image6'
            style={{
              position: width > 1100 ? 'absolute' : 'relative',
            }}
          />

          <p className='bottomtext'>
            <Link to='/contact'>Contact</Link> me for access to PDF.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query jordenimages {
    image1: file(relativePath: { eq: "jorden/1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "jorden/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "jorden/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "jorden/4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "jorden/5.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "jorden/6.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default JordenBrenner;
